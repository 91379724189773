/* -----Color----- */
$FirstColor: black;
$SecondColor: rgb(255, 255, 255);
$ThirdColor: #73ec8b;
$FoughtColor: #ffebd4;
$FivesColor: #f5f5f5;
$SixesColor: black;
$SeventhsColor: black;

$headerHeight: 50px;
$mainWidth: 1200px;

$transitionBasic: all 0.7s;

$boxShadowBasic: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
$boxShadowBasic-: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
