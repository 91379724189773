@import './all.scss';

* {
  text-decoration: none;
  font-size: 18px;
  color: $FirstColor;
  background-color: $FivesColor;
  margin: 0;
  padding: 0;
  /* border: 0; */
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /*  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: auto;
    grid-template: auto;
    justify-items: stretch;
    justify-content: center;
    align-items: start;
    height: fit-content;
    width: $mainWidth;
    margin: 20px auto;
    padding: 20px auto;
  } */

  .root {
    display: flex;
    flex-direction: column;
    .container {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(230px, 1fr)
      ); // Автоматическое изменение числа колонок
      gap: 2%; // Зазор между блоками
      justify-items: center;
      justify-content: center;
      align-items: start;
      width: $mainWidth;
      margin: 0 auto;
      padding: 0;
      height: fit-content;

      @media (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
