@import '../variables';

header {
  height: $headerHeight;
  width: 100%;
  background-color: $FoughtColor;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;

  box-shadow: 2px 2px rgba(0, 0, 0, 0.8) !important;

  .header-block {
    display: flex;
    flex-wrap: inherit;
    flex-direction: inherit;
    height: 100%;
    width: 1200px;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;

    .logoSide {
      height: 80%;
      width: auto;
      align-content: center;
      border-radius: 20px;

      a {
        display: flex;
        flex-direction: inherit;
        width: fit-content;
        height: 100%;
        background-color: #7bd3f7;
        border-radius: inherit;
        transition: $transitionBasic;
        /* padding: 1px; */
        border-radius: 20px;

        &:hover {
          transition: all 0.1s;
          svg g {
            fill: rgb(197, 25, 25);
            transition: $transitionBasic;
          }
          div {
            transition: $transitionBasic;
            color: rgba($color: $SecondColor, $alpha: 1);
            text-shadow: 2px 2px rgba($color: rgb(197, 25, 25), $alpha: 0.3);
          }
        }

        svg {
          font-size: initial;
          width: fit-content;
          height: 100%;
          background-color: transparent;
          margin-left: 3px;
          transition: $transitionBasic;
          g {
            transition: $transitionBasic;
            fill: #ffeb00;
          }
        }
        div {
          font-size: 30px;
          font-family: 'Caveat', cursive;
          text-align: center;
          font-weight: 600;
          color: $SecondColor;
          padding: 0 10px 0 0;
          background-color: transparent;
          transition: $transitionBasic;
          text-shadow: 2px 2px rgba($color: #ffeb00, $alpha: 0.3);
          margin-right: 3px;
        }
      }
    }

    .menuSide {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      width: fit-content;
      align-content: flex-end;
      align-items: center;
      background-color: inherit;

      a {
        font-size: inherit;
        color: $FirstColor;
        text-align: center;
        background-color: $FivesColor;
        padding: 5px 10px;
        border-radius: 10px;
        box-shadow: $boxShadowBasic-;
        transition: all 0.5s;
        &:hover {
          background-color: $FirstColor;
          color: $SecondColor;
          transition: all 0.5s;
        }
        &:last-child {
          margin-right: 30px;
        }
      }
    }
  }
}
