@import '../variables';

hr {
  margin: 10px auto;
  width: 100%;
}
.containerAnketa {
  width: $mainWidth;
  margin: 0 auto;
  .anketa {
    display: flex;
    flex-direction: column;
    h1 {
      margin: 10px auto 0;
    }
    h2 {
      text-align: left;
    }

    p.dearGuests {
      text-align: left;
      margin: 10px auto 0;
      font-style: italic;
    }

    form.formaShmorma {
      display: flex;
      flex-direction: column;
      align-items: start;

      .blockTop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        h2 {
          align-items: flex-start;
          text-align: left;
        }
        p.title {
          /* margin: 10px auto 0; */
          font-weight: 700;
        }

        .miniBlock {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 10px auto 0;
          label {
            margin: 0 10px 0 0;
            width: max-content;
          }
          select {
            width: max-content;
          }
        }
      }
    }
    button {
      font-size: inherit;
      color: $FirstColor;
      text-align: center;
      background-color: $FivesColor;
      padding: 5px 10px;
      border-radius: 10px;
      box-shadow: $boxShadowBasic-;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        background-color: $FirstColor;
        color: $SecondColor;
        transition: all 0.5s;
      }
    }
  }
}
