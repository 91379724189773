@import '../variables';

.block {
  width: min-content; // Ширина адаптируется к колонке
  height: max-content; // Минимальная высота
  display: flex;
  flex-direction: column;
  background-color: $SecondColor;
  padding: 10px;
  border-radius: 5px;
  /*  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.3); */
  box-shadow: $boxShadowBasic;
  transition: all 1s;

  cursor: pointer;

  &:hover &-image img {
    transform: scale(1.05); // Увеличение блока на 5%
    transition: all 0.7s;
  }

  &:hover {
    transition: all 0.7s;
    background-color: $FoughtColor;
  }

  &-image {
    width: 260px;
    height: 260px;
    background-color: inherit;
    position: relative;
    overflow: hidden;
    padding: 10px;
    border-radius: 3px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      background-color: inherit;
      transform: scale(1);
      transition: all 1s;
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: inherit;

    .title {
      font-weight: 700;
      background-color: inherit;
    }

    .text {
      text-align: left;
      height: 50px;
      background-color: inherit;
    }
    hr {
      height: 1px;
      width: 100%;
      background-color: $FirstColor;
      margin-bottom: 5px;
      opacity: 0.2;
    }
    .price {
      text-align: right;
      background-color: inherit;
    }
  }
}
