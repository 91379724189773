@import '../variables';

.category-container {
  height: $headerHeight;
  width: 100%;
  background-color: inherit;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  .category-block {
    display: flex;
    flex-wrap: inherit;
    flex-direction: inherit;
    height: 100%;
    width: 1200px;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    margin: 0 auto;

    .category {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      width: fit-content;
      align-content: flex-end;
      align-items: center;
      background-color: inherit;

      a {
        font-size: inherit;
        margin-left: 20px;
        color: $FirstColor;
        text-align: center;
        background-color: $FoughtColor;
        padding: 5px 15px;
        border-radius: 10px;
        transition: $transitionBasic;
        box-shadow: $boxShadowBasic-;

        &:hover,
        &.active {
          background-color: $FirstColor;
          transition: $transitionBasic;
          color: $SecondColor;
        }

        &:last-child {
          margin-right: 20px;
        }
      }
    }
  }
}
